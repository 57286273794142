import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to bxUIEAllFeatures6!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'QrCodes'}  onPress={() => navigation.navigate("QrCodes")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'AutomaticRenewals'}  onPress={() => navigation.navigate("AutomaticRenewals")} />
<CustomTextItem content={'Reviews'}  onPress={() => navigation.navigate("Reviews")} />
<CustomTextItem content={'Maps'}  onPress={() => navigation.navigate("Maps")} />
<CustomTextItem content={'MultipleCurrencySupport'}  onPress={() => navigation.navigate("MultipleCurrencySupport")} />
<CustomTextItem content={'PricingEngine2'}  onPress={() => navigation.navigate("PricingEngine2")} />
<CustomTextItem content={'SavedCards'}  onPress={() => navigation.navigate("SavedCards")} />
<CustomTextItem content={'FeedbackCollection'}  onPress={() => navigation.navigate("FeedbackCollection")} />
<CustomTextItem content={'OrderSummary'}  onPress={() => navigation.navigate("OrderSummary")} />
<CustomTextItem content={'Payments'}  onPress={() => navigation.navigate("Payments")} />
<CustomTextItem content={'SpendAnalysis'}  onPress={() => navigation.navigate("SpendAnalysis")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'ItemAvailability'}  onPress={() => navigation.navigate("ItemAvailability")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'RefundManagement'}  onPress={() => navigation.navigate("RefundManagement")} />
<CustomTextItem content={'RequestManagement'}  onPress={() => navigation.navigate("RequestManagement")} />
<CustomTextItem content={'ReviewPrompt'}  onPress={() => navigation.navigate("ReviewPrompt")} />
<CustomTextItem content={'SplitPayments'}  onPress={() => navigation.navigate("SplitPayments")} />
<CustomTextItem content={'Studioadminrecord99'}  onPress={() => navigation.navigate("Studioadminrecord99")} />
<CustomTextItem content={'SummaryCard'}  onPress={() => navigation.navigate("SummaryCard")} />
<CustomTextItem content={'AcceptPrepayments2'}  onPress={() => navigation.navigate("AcceptPrepayments2")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'Crm3rdPartyIntegration'}  onPress={() => navigation.navigate("Crm3rdPartyIntegration")} />
<CustomTextItem content={'PersonalityTest'}  onPress={() => navigation.navigate("PersonalityTest")} />
<CustomTextItem content={'Feedback'}  onPress={() => navigation.navigate("Feedback")} />
<CustomTextItem content={'UsageInsights'}  onPress={() => navigation.navigate("UsageInsights")} />
<CustomTextItem content={'Polling'}  onPress={() => navigation.navigate("Polling")} />
<CustomTextItem content={'PremiumPlanComparison'}  onPress={() => navigation.navigate("PremiumPlanComparison")} />
<CustomTextItem content={'RestorePurchase2'}  onPress={() => navigation.navigate("RestorePurchase2")} />
<CustomTextItem content={'Rfid'}  onPress={() => navigation.navigate("Rfid")} />
<CustomTextItem content={'SubscriptionBilling'}  onPress={() => navigation.navigate("SubscriptionBilling")} />
<CustomTextItem content={'Surveys'}  onPress={() => navigation.navigate("Surveys")} />
<CustomTextItem content={'TaxCalculator'}  onPress={() => navigation.navigate("TaxCalculator")} />
<CustomTextItem content={'TicketSystem'}  onPress={() => navigation.navigate("TicketSystem")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'BarcodesScanner'}  onPress={() => navigation.navigate("BarcodesScanner")} />
<CustomTextItem content={'InappPurchasing'}  onPress={() => navigation.navigate("InappPurchasing")} />
<CustomTextItem content={'CentralisedBilling'}  onPress={() => navigation.navigate("CentralisedBilling")} />
<CustomTextItem content={'CollectTransactionFees'}  onPress={() => navigation.navigate("CollectTransactionFees")} />
<CustomTextItem content={'ItemGrouper'}  onPress={() => navigation.navigate("ItemGrouper")} />
<CustomTextItem content={'DigitalSignatures'}  onPress={() => navigation.navigate("DigitalSignatures")} />
<CustomTextItem content={'DonationPayments'}  onPress={() => navigation.navigate("DonationPayments")} />
<CustomTextItem content={'BitcoinPayment'}  onPress={() => navigation.navigate("BitcoinPayment")} />
<CustomTextItem content={'ManualTransactions2'}  onPress={() => navigation.navigate("ManualTransactions2")} />
<CustomTextItem content={'MapSettings'}  onPress={() => navigation.navigate("MapSettings")} />
<CustomTextItem content={'MultitieredPricing'}  onPress={() => navigation.navigate("MultitieredPricing")} />
<CustomTextItem content={'PaymentAdmin2'}  onPress={() => navigation.navigate("PaymentAdmin2")} />

<CustomTextItem content={'Ordermanagement'}  onPress={() => navigation.navigate("Ordermanagement")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

<CustomTextItem content={'ContactsList'}  onPress={() => navigation.navigate("ContactsList")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;