// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import InappPurchasing from "../../blocks/InappPurchasing/src/InappPurchasing";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import Maps from "../../blocks/maps/src/Maps";
import Feedback from "../../blocks/Feedback/src/Feedback";
import UsageInsights from "../../blocks/UsageInsights/src/UsageInsights";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import SummaryCard from "../../blocks/SummaryCard/src/SummaryCard";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import DonationPayments from "../../blocks/DonationPayments/src/DonationPayments";
import Polling from "../../blocks/Polling/src/Polling";
import Crm3rdPartyIntegration from "../../blocks/Crm3rdPartyIntegration/src/Crm3rdPartyIntegration";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import SavedCards from "../../blocks/SavedCards/src/SavedCards";
import MultitieredPricing from "../../blocks/MultitieredPricing/src/MultitieredPricing";
import BitcoinPayment from "../../blocks/BitcoinPayment/src/BitcoinPayment";
import ManualTransactions2 from "../../blocks/ManualTransactions2/src/ManualTransactions2";
import RestorePurchase2 from "../../blocks/RestorePurchase2/src/RestorePurchase2";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import DigitalSignatures from "../../blocks/DigitalSignatures/src/DigitalSignatures";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import ItemGrouper from "../../blocks/ItemGrouper/src/ItemGrouper";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import MapSettings from "../../blocks/MapSettings/src/MapSettings";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import PersonalityTest from "../../blocks/PersonalityTest/src/PersonalityTest";
import SpendAnalysis from "../../blocks/SpendAnalysis/src/SpendAnalysis";
import PricingEngine2 from "../../blocks/PricingEngine2/src/PricingEngine2";
import Payments from "../../blocks/Payments/src/Payments";
import AcceptPrepayments2 from "../../blocks/AcceptPrepayments2/src/AcceptPrepayments2";
import FeedbackCollection from "../../blocks/FeedbackCollection/src/FeedbackCollection";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import Surveys from "../../blocks/Surveys/src/Surveys";
import Studioadminrecord99 from "../../blocks/Studioadminrecord99/src/Studioadminrecord99";
import Rfid from "../../blocks/Rfid/src/Rfid";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import ContactsList from "../../blocks/ContactsList/src/ContactsList";





const routeMap = {
ContactsList:{
 component:ContactsList,
path:"/ContactsList"},

Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},

MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
InappPurchasing:{
 component:InappPurchasing,
path:"/InappPurchasing"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
Maps:{
 component:Maps,
path:"/Maps"},
Feedback:{
 component:Feedback,
path:"/Feedback"},
UsageInsights:{
 component:UsageInsights,
path:"/UsageInsights"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
SummaryCard:{
 component:SummaryCard,
path:"/SummaryCard"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
TaxCalculator:{
 component:TaxCalculator,
path:"/TaxCalculator"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
DonationPayments:{
 component:DonationPayments,
path:"/DonationPayments"},
Polling:{
 component:Polling,
path:"/Polling"},
Crm3rdPartyIntegration:{
 component:Crm3rdPartyIntegration,
path:"/Crm3rdPartyIntegration"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
SavedCards:{
 component:SavedCards,
path:"/SavedCards"},
MultitieredPricing:{
 component:MultitieredPricing,
path:"/MultitieredPricing"},
BitcoinPayment:{
 component:BitcoinPayment,
path:"/BitcoinPayment"},
ManualTransactions2:{
 component:ManualTransactions2,
path:"/ManualTransactions2"},
RestorePurchase2:{
 component:RestorePurchase2,
path:"/RestorePurchase2"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
DigitalSignatures:{
 component:DigitalSignatures,
path:"/DigitalSignatures"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
ItemGrouper:{
 component:ItemGrouper,
path:"/ItemGrouper"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
MapSettings:{
 component:MapSettings,
path:"/MapSettings"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
PersonalityTest:{
 component:PersonalityTest,
path:"/PersonalityTest"},
SpendAnalysis:{
 component:SpendAnalysis,
path:"/SpendAnalysis"},
PricingEngine2:{
 component:PricingEngine2,
path:"/PricingEngine2"},
Payments:{
 component:Payments,
path:"/Payments"},
AcceptPrepayments2:{
 component:AcceptPrepayments2,
path:"/AcceptPrepayments2"},
FeedbackCollection:{
 component:FeedbackCollection,
path:"/FeedbackCollection"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
Studioadminrecord99:{
 component:Studioadminrecord99,
path:"/Studioadminrecord99"},
Rfid:{
 component:Rfid,
path:"/Rfid"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;